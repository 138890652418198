<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validWorkNo = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("编号格式不对"));
      } else {
        callback();
      }
    }
  };
let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validCardNo = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("IC卡号格式不对"));
      } else {
        callback();
      }
    }
  };
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "通行记录",
        search: [
          {
            key: "passTime",
            label: "通行时段",
            type: "time-range",
            defaultValue: ["00:00:00", "23:59:59"]
          },
          {
            key: "workNo",
            label: "编号",
            maxlength: 32,
              rules: [{
                validator: validWorkNo,
                trigger: ['blur']
              }]
          },
          {
            key: "name",
            label: "姓名",
             maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
          },
            {
              key: "cardNo",
              label: "IC卡号",
              maxlength: 32,
              rules: [{
                validator: validCardNo,
                trigger: ['blur']
              }]
            }
        ],
        columns: [
          {
            label: "姓名",
            key: "name",
            align: "center",
            on: row => {
              _this.$router.push("/main/PassRecord/detail/show/" + row.id);
            }
          },
          { label: "编号", key: "workNo", align: "center" },
          {
            label: "通行时间",
            key: "passTime",
            align: "center",
            width: 160
          },
          { label: "性别", key: "gender" },
          { label: "卡号", key: "cardNo", width: 200, align: "center" },
          {
            label: "身份证地址（省-市）",
            key: "address",
            width: 400,
            align: "center"
          },
          { label: "体温", key: "temperature", align: "center" },
          { label: "是否带口罩", key: "isMasks", align: "center" },
          {
            label: "通行照片",
            width: 200,
            key: "authPicUrl",
            type: "img",
            on: row => {
              _this.$alert(`<img src="${row.authPicUrl}">`, "查看头像", {
                dangerouslyUseHTMLString: true,
                callback: action => {}
              });
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("user-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        orderByField: "pass_time",
        sort: false,
        startDate: opt.searchForm["passTime"]
          ? this.format(opt.searchForm["passTime"][0])
          : null,
        endDate: opt.searchForm["passTime"]
          ? this.format(opt.searchForm["passTime"][1])
          : null,
        name: opt.searchForm["name"],
        workNo: opt.searchForm["workNo"],
        cardNo: opt.searchForm["cardNo"]
      };
      this.post("device-service/tempPassing/page/query", dto,{
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.isMasks = ["--", "是"][item.isMasks];
          item.address = item.address ? item.address : "--";
          if(item.address=='null '){
            item.address="--"
          }
          item.name = item.name ? item.name : "匿名";
          item.type = item.type
            ? ["普通用户", "黑名单", "白名单"][item.type]
            : "普通用户";
          if (item.resultState != 1 && item.temperature != "--") {
            item.bk = 4;
          }
          item.workNo = item.workNo ? item.workNo : "--";
          item.cardNo = item.cardNo ? item.cardNo : "--";
          item.temperature = item.temperature ? item.temperature : "--";
          item.passTime = item.passTime ? item.passTime : "--";
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>
<style  lang="scss">
.user-list {
  .el-dialog {
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
  .el-message-box {
    width: 780px;
    p {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }

  .guard-log {
    .danger {
      color: #f56c6c;
    }

    .info {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;
      background-color: #e4e7ed;

      .txt {
        margin-right: 20px;
      }
    }
  }
  .fullTable {
    .el-table__row {
      height: 100px;
    }
    .el-table {
      max-height: 750px;
      overflow: auto;
    }
    img {
      width: 80% !important;
      height: 93% !important;
      left: initial;
    }
  }
}
</style>
